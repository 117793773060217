import React, { useContext, useEffect } from "react";
import { FirebaseContext } from "../components/Firebase";
import NeedConfirmation from "../containers/Authentication/NeedConfirmation";
import Success from "../containers/Authentication/Success";
import ThickLoadingWall from "../components/ThickLoadingWall";

const VerifyEmail = () => {
  const { user, firebase, loading } = useContext(FirebaseContext);
  // //console.log(user.emailVerified);

  return (
    <div>
      {loading && <ThickLoadingWall />}

      <NeedConfirmation firebase={firebase} />
    </div>
  );
};

export default VerifyEmail;
